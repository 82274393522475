import React, { useEffect, useState } from 'react';
import { Message, PaginationMeta, SearchParams } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import Listing from 'modules/Layout/component/Listing';
import CardBox from 'modules/Layout/component/CardBox';
import Alert from 'modules/Layout/component/Alert';
import PerPageTable from 'modules/Layout/component/Pagination/WithProps/PerPage';
import PaginationTable from 'modules/Layout/component/Pagination/WithProps';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchFinancialReporstList } from 'modules/Executor/repository';
import { useDispatch } from 'react-redux';
import { ExecutorFinancialReport } from '../../../Executor/model/Violation';
import FinancialReportsExecutorTable from '../../../Executor/container/FinancialReports';
import { addToastAction } from '../../../Layout/action';
import serverErrorToast from '../../../Shared/action/errorToast';
import { orderFileAddToastSuccess, orderFileDeletedToastSuccess, orderFileDownloadToastSuccess } from '../../toasts';
import fetchFileRequest from '../../../Shared/helper/APIRequests/fetchFileRequest';
import {
  addExecutorFinancialReportFile,
  deleteExecutorFinancialReportFile,
  fetchExecutorFinancialReportFile
} from '../../repository';
import { updateRequestWithData } from '../../../Shared/helper/APIRequests/updateRequest';
import GenericModalDelete from '../../../Layout/component/Modal/GenericDeleteModal';
import ModalAddSingleFileDragDrop from '../../../Layout/component/Modal/AddSingleFileDragDropModal';

export interface Props {
  executor_id?: string | number;
}

const DetailsFinancialReportsExecutor: React.FC<Props> = ({ executor_id }) => {
  const dispatch = useDispatch();
  const [financialReports, setFinancialReports] = useState<ExecutorFinancialReport[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [displayExecutorFinancialReportModal, setDisplayExecutorFinancialReportModal] = useState<number | string>(null);
  const [displayFileDeleteModal, setDisplayFileDeleteModal] = useState<ExecutorFinancialReport | null>(null);

  const resetMessage = () => setMessage(null);
  const dispatchErrorMessageToast = (errMessage: string) =>
    dispatch(addToastAction(serverErrorToast(errMessage, 'Błąd zarządzania plikami')));

  const dispatchFinancialReportFileDeleteToast = () => dispatch(addToastAction(orderFileDeletedToastSuccess()));

  const dispatchFinancialReportFileDownloadToast = () => dispatch(addToastAction(orderFileDownloadToastSuccess()));

  const dispatchFinancialReportFileAddToast = () => dispatch(addToastAction(orderFileAddToastSuccess()));

  const cancelToken = useCancelToken();
  const fetchFinancialReports = async (payload?: SearchParams) => {
    setLoading(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchFinancialReporstList, executor_id, payload, cancelToken);

    if (cancelled) return;
    if (data) {
      setFinancialReports(data.data);
      setMeta(data.meta);
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (executor_id) {
      fetchFinancialReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executor_id]);

  const deleteFinancialReportFileAction = async (financial_report_id: number | string) => {
    if (loadingFile) return;
    setLoadingFile(true);

    if (message) setMessage(null);

    const {
      cancelled,
      message: messageRes,
      data: resDeleteData
    } = await updateRequestWithData(deleteExecutorFinancialReportFile, executor_id, financial_report_id, cancelToken);

    if (cancelled) return;
    if (resDeleteData) {
      dispatchFinancialReportFileDeleteToast();
      setDisplayFileDeleteModal(null);
      fetchFinancialReports();
    }
    if (messageRes) {
      setMessage(messageRes);
      dispatchErrorMessageToast(messageRes.value);
    }
    setLoadingFile(false);
  };

  const executorFinancialReportDownloadFileRequest = async (financial_report_id: number | string) => {
    if (loadingFile) return;

    if (message) setMessage(null);

    setLoadingFile(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await fetchFileRequest(fetchExecutorFinancialReportFile, executor_id, financial_report_id, cancelToken);

    if (cancelled) return;
    if (success) dispatchFinancialReportFileDownloadToast();
    if (messageRes) {
      dispatchErrorMessageToast(messageRes.value);
    }
    setLoadingFile(false);
  };

  const orderAddFileRequest = async (financial_report_id: number | string, data: FormData) => {
    const {
      cancelled,
      message: messageRes,
      data: resAddData
    } = await updateRequestWithData(
      addExecutorFinancialReportFile,
      executor_id,
      financial_report_id,
      data,
      cancelToken
    );

    if (cancelled) return;
    if (resAddData) {
      dispatchFinancialReportFileAddToast();
      setDisplayExecutorFinancialReportModal(null);
      fetchFinancialReports();
    }
    if (messageRes) {
      dispatchErrorMessageToast(messageRes.value);
    }
  };

  return (
    <>
      <CardBox heading="Sprawozdania finansowe z KRS">
        <div className="row users-view">
          <div className="col-12">
            <Alert message={message} />
            <div className="pzpeu-table-list inside-card-box">
              <Listing
                table={
                  <FinancialReportsExecutorTable
                    downloadFile={executorFinancialReportDownloadFileRequest}
                    showModal={setDisplayExecutorFinancialReportModal}
                    onDeleteClick={(financialReport) => setDisplayFileDeleteModal(financialReport)}
                    executor_id={executor_id}
                    financialReports={financialReports}
                  />
                }
                pagination={<PaginationTable fetchList={fetchFinancialReports} meta={meta} />}
                perPage={<PerPageTable fetchList={fetchFinancialReports} meta={meta} />}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </CardBox>
      {displayFileDeleteModal && (
        <GenericModalDelete
          value={displayFileDeleteModal}
          title="Usunięcie pliku"
          content={(value) => (
            <div className="modal-body-title">
              Czy na pewno chcesz usunąć plik - <span className="h5">{value?.file_name}?</span>
            </div>
          )}
          onDeleteClick={() => deleteFinancialReportFileAction(displayFileDeleteModal.id)}
          isOpen={!!displayFileDeleteModal}
          toggle={() => setDisplayFileDeleteModal(null)}
          loading={loading}
          message={message}
          resetMessage={resetMessage}
        />
      )}
      {displayExecutorFinancialReportModal && (
        <ModalAddSingleFileDragDrop
          title="Dodaj plik"
          onAddFilesRequest={(data) => orderAddFileRequest(displayExecutorFinancialReportModal, data)}
          isOpen={!!displayExecutorFinancialReportModal}
          toggle={() => setDisplayExecutorFinancialReportModal(null)}
          loading={loading}
          message={message}
          resetMessage={resetMessage}
        />
      )}
    </>
  );
};

export default DetailsFinancialReportsExecutor;
